import React from "react";
import Loader from "react-loader-spinner";

const Loading = ({ loading, type = "ThreeDots" }) => {
	return (
		<div style={{ textAlign: "center" }}>
			<Loader
				visible={loading}
				type={type}
				color="#00BFFF"
				height={50}
				width={50}
			/>
		</div>
	);
};

export default Loading;

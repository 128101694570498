import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { logout } from "../features/user/userSlice";
import { selectToken } from "../features/user/userSlice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Logout = (props) => {
	const dispatch = useDispatch();
	const token = useSelector(selectToken);
	const history = useHistory();

	useEffect(() => {
		axios
			.get(
				`${
					process.env.NODE_ENV === "production"
						? process.env.REACT_APP_URL
						: "http://127.0.0.1:8000"
				}/api/logout`,
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			)
			.finally(() => {
				dispatch(logout());

				history.push("/");
			});
	}, [dispatch, token, history]);

	return <>{props.children}</>;
};

export default Logout;

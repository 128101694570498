import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import styled from "styled-components";

const Item = styled.li`
	height: 50px;
	text-align: center;
`;

const MenuItem = (props) => {
	const [hover, setHover] = useState(false);
	return (
		<Item
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<NavLink
				to={props.to}
				className="link_parent"
				exact
				activeClassName="myTestClass"
				activeStyle={{ background: "#8400ff" }}
				style={hover ? { background: "#8400ff" } : { color: "white" }}
			>
				<FontAwesomeIcon
					className="bx bx-grid-alt icon_m"
					icon={props.icon}
					size="2x"
					style={hover ? {} : { color: "white" }}
					inverse
				></FontAwesomeIcon>
				<span className="links_name" style={hover ? { color: "white" } : {}}>
					{props.name}
				</span>
			</NavLink>
			<span className="tooltip">{props.name}</span>
		</Item>
	);
};

export default MenuItem;

import React from "react";
import { useSelector } from "react-redux";
import { selectStatus } from "../features/user/userSlice";
import { Route, Redirect, useLocation } from "react-router-dom";

const GuestRoute = (props) => {
	const location = useLocation();
	const authLogin = useSelector(selectStatus);

	return authLogin ? (
		<Redirect
			to={{
				pathname: "/user",
				state: { from: location },
			}}
		/>
	) : (
		<Route {...props} />
	);
};

export default GuestRoute;

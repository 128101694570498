import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import SideMenu from "./components/SideMenu.jsx";
import {
	faCheckSquare,
	faBars,
	faHome,
	faCalculator,
	faUsers,
	faSitemap,
	faList,
	faFileInvoiceDollar,
	faFile,
	faCashRegister,
	faChartBar,
} from "@fortawesome/free-solid-svg-icons";
import UserArea from "./views/UserArea";
import styled from "styled-components";
import Logout from "./components/Logout";
import Header from "./components/Header/Header";
import ScrollToTop from "./components/includes/ScrollTop.jsx";
import Loading from "./components/includes/Loading";

const CreateQuote = React.lazy(() => import("./views/CreateQuote"));
const Customers = React.lazy(() => import("./views/Customers"));
const Inventory = React.lazy(() => import("./views/Items"));
const AllQuotes = React.lazy(() => import("./views/AllQuotes"));
const EditQuote = React.lazy(() => import("./views/EditQuote"));
const AllInvoices = React.lazy(() => import("./views/AllInvoices"));
const EditInvoice = React.lazy(() => import("./views/EditInvoice"));
const CreateInvoice = React.lazy(() => import("./views/CreateInvoice"));
const MakePayment = React.lazy(() => import("./views/MakePayment"));
const AllPayments = React.lazy(() => import("./views/Payments"));
const ViewInvoice = React.lazy(() => import("./views/ViewInvoice"));
const ViewQuote = React.lazy(() => import("./views/ViewQuote"));
const Settings = React.lazy(() => import("./views/Settings"));
const CashInvoice = React.lazy(() => import("./views/CashInvoice"));
const Reports = React.lazy(() => import("./views/Reports"));
const SalesPerson = React.lazy(() => import("./views/SalesPerson"));
const NoteScreen = React.lazy(() => import("./views/NoteScreen"));
const AllNotesScreen = React.lazy(() => import("./views/AllNotesScreen"));
const EditNotesScreen = React.lazy(() => import("./views/EditNotesScreen"));

library.add(
	fab,
	faCheckSquare,
	faBars,
	faHome,
	faCalculator,
	faUsers,
	faSitemap,
	faList,
	faFileInvoiceDollar,
	faFile,
	faCashRegister,
	faChartBar
);

const Container = styled.div`
	background-color: rgb(228, 233, 247);
	height: 100vh;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 2%;
`;

const Back = styled.section`
	background-color: white;
`;

function App() {
	return (
		<Router>
			<ScrollToTop />
			<SideMenu />
			<Back className="home-section">
				<Header />
				<Container className="container-fluid enclosure">
					<Suspense fallback={<Loading loading={true} />}>
						<div style={{ backgroundColor: "white", padding: "20px" }}>
							<Switch>
								<Route path="/user" exact>
									<UserArea />
								</Route>
								<Route
									path="/user/create-quote"
									component={CreateQuote}
									exact
								/>
								<Route path="/user/customers" component={Customers} exact />
								<Route path="/user/inventory" component={Inventory} exact />
								<Route path="/user/quotes" component={AllQuotes} exact />
								<Route
									path="/user/edit-quote/:id"
									component={EditQuote}
									exact
								/>
								<Route
									path="/user/create-invoice"
									component={CreateInvoice}
									exact
								/>
								<Route path="/user/invoices" component={AllInvoices} exact />
								<Route
									path="/user/edit-invoice/:id"
									component={EditInvoice}
									exact
								/>
								<Route
									path="/user/make-payment/:id"
									component={MakePayment}
									exact
								/>
								<Route
									path="/user/all-payments/:id"
									component={AllPayments}
									exact
								/>
								<Route
									path="/user/view-invoice/:id"
									component={ViewInvoice}
									exact
								/>
								<Route
									path="/user/view-quote/:id"
									component={ViewQuote}
									exact
								/>
								<Route
									path="/user/create-cash-invoice"
									component={CashInvoice}
									exact
								/>
								<Route
									path="/user/sales-person"
									component={SalesPerson}
									exact
								/>
								<Route
									path="/user/new-credit-note"
									component={NoteScreen}
									exact
								/>
								<Route
									path="/user/all-credit-notes"
									component={AllNotesScreen}
									exact
								/>
								<Route
									path="/user/edit-credit-note/:id"
									component={EditNotesScreen}
									exact
								/>
								<Route path="/user/reports" component={Reports} exact />
								<Route path="/user/settings" component={Settings} exact />
								<Route path="/logout" component={Logout} exact />
							</Switch>
						</div>
					</Suspense>
				</Container>
			</Back>
		</Router>
	);
}

export default App;

import React, { useState } from "react";
import styled from "styled-components";
import Input from "../components/Input";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { login } from "../features/user/userSlice";
import { Helmet } from "react-helmet-async";
import Loader from "react-loader-spinner";

import { useAlert } from "react-alert";
import axios from "axios";

const Heading = styled.div`
	font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
	font-size: 2.5rem;
	color: palevioletred;
	font-weight: bold;
`;

const Container = styled.div`
	font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
	border-radius: 5px;
	margin-top: 10%;
`;

const Button = styled.button`
	margin-top: 10px;
`;

const Login = () => {
	const alert = useAlert();

	const dispatch = useDispatch();

	const history = useHistory();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const submit = () => {
		setLoading(true);

		axios
			.get(
				`${
					process.env.NODE_ENV === "production"
						? process.env.REACT_APP_URL
						: "http://127.0.0.1:8000"
				}/sanctum/csrf-cookie`
			)
			.then(() => {
				axios
					.post(
						`${
							process.env.NODE_ENV === "production"
								? process.env.REACT_APP_URL
								: "http://127.0.0.1:8000"
						}/api/login`,
						{
							email: username,
							password: password,
						}
					)
					.then((response) => {
						alert.success("Logged In");
						setLoading(false);
						dispatch(
							login({
								user: response.data.user,
								token: response.data.token,
								branch: response.data.branch,
							})
						);
						history.push("/user");
					})
					.catch((err) => {
						alert.error(err.response.data.message);
						setLoading(false);
					});
			});
	};

	const isDisabled = () => {
		if (loading) {
			return true;
		}
		return !(username && password);
	};

	return (
		<>
			<Helmet>
				<title>Login</title>
			</Helmet>
			<div className="App">
				<Container className="container">
					<Heading>Login</Heading>

					<Input
						placeholder="Username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						type="text"
						label="Username"
					/>
					<Input
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type="password"
						label="Password"
					/>
					<Button
						type="submit"
						className="btn btn-lg btn-primary"
						disabled={isDisabled()}
						onClick={submit}
					>
						Submit
					</Button>
					<Loader
						visible={loading}
						type="ThreeDots"
						color="#00BFFF"
						height={50}
						width={50}
					/>
				</Container>
			</div>
		</>
	);
};

export default Login;

import React from "react";
import Tab from "./Tiles/Tab";
import "./Dashboard.css";

const Dashboard = () => {
	return (
		<>
			<div className="text">Dashboard</div>
			<div className="masonry-container">
				<Tab text="All Quotes" link="/user/quotes" />
				<Tab text="New Quote" link="/user/create-quote" />
				<Tab text="Customers List" link="/user/customers" />
				<Tab text="Inventory" link="/user/inventory" />
				<Tab text="New Invoice" link="/user/create-invoice" />
				<Tab text="Cash Invoice" link="/user/create-cash-invoice" />
				<Tab text="All Invoices" link="/user/invoices" />
				<Tab text="Reports" link="/user/reports" />
				<Tab text="Sales Person" link="/user/sales-person" />
				<Tab text="Create Credit Note" link="/user/new-credit-note" />
				<Tab text="All Credit Notes" link="/user/all-credit-notes" />
			</div>
		</>
	);
};

export default Dashboard;

import React from "react";
import { useSelector } from "react-redux";
import { selectBranch } from "../../features/user/userSlice";
import { NavLink, Link } from "react-router-dom";
import { withRouter } from "react-router";
import "./Header.css";

const Header = () => {
	const name = useSelector(selectBranch).name;

	return (
		<div className="header">
			<Link to="/user" className="logo">
				Quote System
			</Link>
			<div className="header-right">
				<NavLink exact to="/user/settings">
					{name}
				</NavLink>
				<NavLink exact to="/user">
					Home
				</NavLink>
				<NavLink exact to="/logout">
					Logout
				</NavLink>
			</div>
		</div>
	);
};

export default withRouter(Header);

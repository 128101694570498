import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	status: false,
	data: null,
	mode: null,
	q: null,
};

export const editSlice = createSlice({
	name: "edit",
	initialState,
	reducers: {
		setEdit: (state, { payload }) => {
			state.status = true;
			state.data = payload.id;
			state.mode = payload.mode;
			state.q = payload.q;
		},
		removeEdit(state) {
			state.status = false;
			state.data = null;
			state.q = null;
			state.mode = null;
		},
	},
});

export const { setEdit, removeEdit } = editSlice.actions;

export const selectEdit = (state) => state.edit;

export default editSlice.reducer;

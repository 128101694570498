import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isLogged: false,
	user: {},
	token: null,
	branch: {},
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state, { payload }) => {
			state.isLogged = true;
			state.user = payload.user;
			state.token = payload.token;
			state.branch = payload.branch;
		},
		logout(state) {
			state.isLogged = false;
			state.user = {};
			state.token = null;
			state.branch = {};
		},
		update_branch: (state, { payload }) => {
			state.branch = payload.branch;
		},
	},
});

export const { login, logout, update_branch } = userSlice.actions;

export const selectUser = (state) => state.auth.user;

export const selectBranch = (state) => state.auth.branch;

export const selectToken = (state) => state.auth.token;

export const selectStatus = (state) => state.auth.isLogged;

export default userSlice.reducer;

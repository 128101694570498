import React from "react";

const Input = (props) => {
	return (
		<div className="form-group">
			<label htmlFor={props.label && props.label}>{props.label}</label>
			<input
				className={`form-control ${props.error && "error"}`}
				name={props.label}
				{...props}
			/>
		</div>
	);
};

export default Input;

import React from "react";
import Dashboard from "../components/Dashboard";
import { Helmet } from "react-helmet-async";

const UserArea = () => {
	return (
		<>
			<Helmet>
				<title>User Dashboard</title>
			</Helmet>
			<Dashboard />
		</>
	);
};

export default UserArea;

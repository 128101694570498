import { Link } from "react-router-dom";
import "./Tab.css";

const Tab = (props) => {
	return (
		<Link to={props.link}>
			<div className="masonry-brick board">{props.text}</div>
		</Link>
	);
};

export default Tab;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SideMenu.css";
import MenuItem from "./MenuItem";
import styled from "styled-components";
import MenuFooter from "./Footer/MenuFooter";
import { motion } from "framer-motion";

const CloseButton = styled(motion.button)`
	border: 2px solid palevioletred;
	background-color: palevioletred;
	width: 100%;
`;

const SideMenu = () => {
	const [status, setStatus] = useState(true);

	return (
		<div className={`sidebar ${status ? "open" : ""}`}>
			<div className="logo-details">
				<i className="bx bxl-c-plus-plus icon"></i>
				<div className="logo_name">Quotes</div>
				<i
					className={`bx ${status ? "bx-menu-alt-right" : "bx-menu"}`}
					id="btn"
				></i>
			</div>
			<ul className="nav-list">
				{status ? (
					<CloseButton
						className="close-data"
						onClick={() => setStatus(false)}
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}
					>
						<span className="btn btn-lg">Close</span>
						<span className="tooltip">Close</span>
					</CloseButton>
				) : (
					<li>
						<FontAwesomeIcon
							onClick={() => setStatus(true)}
							icon="bars"
							size="2x"
							inverse
						/>
						<span className="tooltip">Open</span>
					</li>
				)}

				<MenuItem name="Dashboard" to="/user" icon="home" />
				<MenuItem name="All Quotes" to="/user/quotes" icon="list" />
				<MenuItem name="New Quote" to="/user/create-quote" icon="calculator" />
				<MenuItem name="Customers List" to="/user/customers" icon="users" />
				<MenuItem name="Inventory" to="/user/inventory" icon="sitemap" />
				<MenuItem name="New Invoices" to="/user/create-invoice" icon="file" />
				<MenuItem
					name="Create Cash Invoices"
					to="/user/create-cash-invoice"
					icon="cash-register"
				/>
				<MenuItem
					name="All Invoices"
					to="/user/invoices"
					icon="file-invoice-dollar"
				/>
				<MenuItem name="Reports" to="/user/reports" icon="chart-bar" />
				<MenuFooter />
			</ul>
		</div>
	);
};

export default SideMenu;

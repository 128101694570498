import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import Login from "./views/Login";
import PrivateRoute from "./components/PrivateRoute";
import GuestRoute from "./components/GuestRoute";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ErrorBoundary from "./components/ErrorBoundary/index.jsx";
import Logout from "./components/Logout";

// optional configuration
const options = {
	// you can also just use 'bottom center'
	position: positions.BOTTOM_CENTER,
	timeout: 5000,
	offset: "30px",
	// you can also just use 'scale'
	transition: transitions.SCALE,
};

let persistor = persistStore(store);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<HelmetProvider>
					<AlertProvider template={AlertTemplate} {...options}>
						<Router>
							<ErrorBoundary>
								<Switch>
									<GuestRoute path="/" exact>
										<Login />
									</GuestRoute>
									<GuestRoute path="/login" exact>
										<Login />
									</GuestRoute>
									<PrivateRoute path="/user" component={App} />
									<PrivateRoute path="/logout" component={Logout} exact />
								</Switch>
							</ErrorBoundary>
						</Router>
					</AlertProvider>
				</HelmetProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
